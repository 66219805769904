/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DownloadOutlined } from '@ant-design/icons';
import { OrderDoc, OrderStatusCode } from '@gooduncles/gu-app-schema';
import { Button } from 'antd';
import { FC } from 'react';
import { getHistoryItems, historyExcelDownload } from 'src/utils/order-history-util';
import { v4 } from 'uuid';

import { numberFormat } from 'src/lib/1/util';
import { getDeliveredAtKrFormat } from 'src/lib/4/order-util';
import { WebViewMessageHandler } from 'src/lib/5/webveiw-message-handler';

import { selectStore } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import useOrders from 'src/hooks/useOrder';
import useStoreIssues from 'src/hooks/useStoreIssues';

import EmptyList from '../Common/EmptyList/EmptyList';
import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import classes from './OrderHistory.module.scss';
import OrderHistoryItem from './OrderHistoryItem/OrderHistoryItem';
import StoreIssueGroup from './StoreIssueGroup/StoreIssueGroup';

/**
 * 화면에 표시할 주문 데이터만 가공한다.
 * @param orders
 * @param commerceConf
 */
const orderFilter = (orders: OrderDoc[]) => {
  return (
    orders
      // 1. 주문완료(수락 전) & 취소 주문은 제외한다.
      .filter((order) => order.orderStatus > 10 && order.orderStatus !== OrderStatusCode.CANCELED)
      // 2. 배송완료 주문의 경우 배송완료일자를 추가한다.
      .map((order) => ({
        ...order,
        deliveredAt: getDeliveredAtKrFormat(order.deliveredAt, order.orderStatus, order._id),
      }))
  );
};

const webViewMessageHandler = WebViewMessageHandler.getInstance();

const OrderHistory: FC = () => {
  const store = useAppSelector(selectStore);
  const { orders } = useOrders();
  const filteredOrders = orders ? orderFilter(Object.values(orders)) : undefined;
  const { storeIssues } = useStoreIssues(store?.storeCode ?? null);
  const historyItems = getHistoryItems(filteredOrders ?? [], storeIssues);

  return (
    <div className={classes.orderHistoryContainer}>
      <TopNavigationBar title='주문 히스토리' leftButton />
      {Object.keys(historyItems)?.length > 0 ? (
        <div className={classes.groupList}>
          <p className={classes.inform}>총 금액은 정산 과정에서 변동될 수 있습니다.</p>
          {Object.entries(historyItems).map(([groupDate, items]) => {
            const issues = items
              .filter((item) => item?._ui?.storeIssues && item._ui.storeIssues.length > 0)
              .flatMap((item) => item._ui!.storeIssues);
            const totalIssueAmount =
              issues.reduce((acc, cur) => acc + ((cur.supplyPrice ?? 0) + (cur.tax ?? 0)) * (cur.volume ?? 1), 0) ?? 0;
            const sumOfGrandTotal = items
              .filter((i) => i.paidAmount && i.orderStatus === OrderStatusCode.DELIVERED)
              .reduce((acc, cur) => acc + cur.paidAmount!, 0);

            return (
              <div className={classes.group} key={groupDate}>
                <div className={classes.border} />
                <div className={classes.summary}>
                  <div className={classes.topRow}>
                    <h3>
                      {groupDate} <span>- {numberFormat(items.length)}건</span>
                    </h3>
                    {/* 현재는 웹에서만 다운로드가 가능하다 */}
                    {webViewMessageHandler.getWebViewRef() ? null : (
                      <Button icon={<DownloadOutlined />} onClick={() => historyExcelDownload(groupDate, items)} />
                    )}
                  </div>
                  <div className={classes.info}>
                    <h2>{numberFormat(sumOfGrandTotal + totalIssueAmount)}</h2>
                    <p>원</p>
                  </div>
                </div>
                <div className={classes.orderList}>
                  {items.map((order) =>
                    order.orderStatus ? (
                      <OrderHistoryItem key={order._id} order={order as OrderDoc} />
                    ) : (
                      <StoreIssueGroup key={v4()} orderDate={order.orderDate} storeIssues={order._ui.storeIssues} />
                    )
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <EmptyList emoji='🧾' message='이전 주문 내역이 없습니다.' />
      )}
    </div>
  );
};

export default OrderHistory;
