import { UserOutlined } from '@ant-design/icons';
import { StoreBase } from '@gooduncles/gu-app-schema';
import { Button, Form, Input } from 'antd';
import { FC, useCallback } from 'react';

import { requiredRule } from '../../../lib/1/string-map';
import { MobileAlert, normalizeEmail, normalizeTel } from '../../../lib/1/util';

import useAuth from 'src/redux/hooks/useAuth';
import { selectIsLoading } from 'src/redux/slices/auth';
import { useAppSelector } from 'src/redux/store';

import classes from './SignupUser.module.scss';

interface SignupUserProps {
  storeBase: StoreBase;
}

type UserFormValues = {
  userid: string;
  userTel: string;
  password: string;
};

const { Item } = Form;

const SignupUser: FC<SignupUserProps> = ({ storeBase }) => {
  const isLoading = useAppSelector(selectIsLoading);
  const { onCreateUser } = useAuth();

  const onFinish = useCallback(
    async (values: UserFormValues) => {
      const { userid: email, userTel, password } = values;
      if (password.length < 6) {
        MobileAlert('비밀번호는 6자 이상이어야 합니다.');
        return;
      }

      await onCreateUser(email, password, userTel, storeBase);
    },
    [onCreateUser, storeBase]
  );

  return (
    <div>
      <Form id='userForm' onFinish={onFinish} size='large'>
        <Item
          id='userid'
          name='userid'
          label='이메일'
          rules={[
            { required: true, message: '필수 항목입니다!' },
            { type: 'email', message: '이메일 형식이 올바르지 않습니다.' },
          ]}
          normalize={normalizeEmail}>
          <Input />
        </Item>
        <Item
          name='userTel'
          label='전화번호'
          rules={[
            ...requiredRule,
            {
              min: 10,
              message: '전화번호 형식이 올바르지 않습니다.',
            },
          ]}
          normalize={normalizeTel}>
          <Input />
        </Item>
        <Item name='password' label='비밀번호' extra='· 6자리 이상' rules={requiredRule} hasFeedback>
          <Input.Password minLength={6} />
        </Item>
        <Item
          name='confirm'
          label='비밀번호 확인'
          hasFeedback
          rules={[
            {
              required: true,
              message: '비밀번호를 확인해주세요.',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
              },
            }),
          ]}>
          <Input.Password />
        </Item>

        <Item>
          <Button
            type='primary'
            icon={<UserOutlined />}
            className={classes.submitUserButton}
            htmlType='submit'
            block
            loading={isLoading}>
            가입하기
          </Button>
        </Item>
      </Form>
    </div>
  );
};

export default SignupUser;
