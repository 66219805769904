import { OrderDoc, OrderStatusCode } from '@gooduncles/gu-app-schema';
import { parse } from 'date-fns';
import { Dictionary } from 'lodash';
import { groupBy, uniq } from 'lodash-es';
import { OrderUI } from 'src/schema/schema-order-ui';

import { formatDate } from 'src/lib/1/date-util';
import { StoreIssueDoc } from 'src/lib/2/schema-store-issue';

import { jsonToExcelFile } from './xlsx-util';

type OrderWithIssues = Partial<OrderDoc> & {
  orderDate: string;
  _ui: {
    storeIssues: StoreIssueDoc[];
  };
};

const groupingRule = (order: OrderDoc) => {
  return formatDate(order.orderDate, 'yy년 M월');
};

/**
 * @param yearMonth 'yy년 M월'
 * @param day 'M월 d일 (EEEEEE)'
 */
const reverseDeliveryDate = (yearMonth: string, day: string) => {
  // 정규 표현식을 사용하여 연도, 월, 일 추출
  const yearRegex = /(\d+)년/;
  const monthDayRegex = /(\d+)월 (\d+)일/;

  const yearMatch = yearRegex.exec(yearMonth);
  const dayMatch = monthDayRegex.exec(day);

  if (yearMatch && dayMatch) {
    // 연도, 월, 일 추출
    const year = '20' + yearMatch[1]; // "2023"
    const month = dayMatch[1]; // "11"
    const date = dayMatch[2]; // "18"

    // ISO 날짜 형식으로 결합
    const fullDate = `${year}-${month}-${date}`;

    // Date 객체로 변환
    const dateObject = parse(fullDate, 'yyyy-MM-dd', new Date());
    return dateObject;
  } else {
    return undefined;
  }
};

/**
 * 세율이 10%인 경우 가격에서 세금을 계산한다.
 */
export const calculatePreTaxPrice = (priceWithTax: number, taxFree: boolean) => {
  if (taxFree) {
    return {
      price: priceWithTax,
      tax: 0,
    };
  }
  // 세전 가격 = 세후 가격 / 1.1
  const preTaxPrice = Math.round(priceWithTax / 1.1);
  const tax = priceWithTax - preTaxPrice;

  return {
    price: preTaxPrice,
    tax,
  };
};

/**
 * 별도의 이슈 컬렉션을 운영하고 있는 과도기에만 사용한다.
 * @param orders
 * @param storeIssues
 * @returns 주문, 주문+이슈, 이슈 세가지의 형태로 된 배열을 리턴한다.
 */
export const getHistoryItems = (
  orders: OrderDoc[],
  storeIssues: StoreIssueDoc[] | undefined
): Dictionary<((OrderDoc & OrderUI) | OrderWithIssues)[]> => {
  const noneDeliveryOrders = orders.filter((order) => order.orderStatus !== OrderStatusCode.DELIVERED);
  /**
   * 이슈 데이터는 배송 완료된 주문과만 연결된다.
   */
  const deliveredOrders = orders.filter(
    (order) => order.deliveredAt && order.orderStatus === OrderStatusCode.DELIVERED
  );

  /**
   * 앱과 별개로 운영되고 있는 이슈 데이터를 주문과 엮는 명확한 규칙은 존재하지 않는다.
   * 때문에 임시로 배송일과 이슈 적용일을 비교 가능한 형태('L월 d일 (EEEEEE)')로 변환한다.
   */
  const groupedByDate = groupBy(storeIssues, (issue) => formatDate(issue.date, 'L월 d일 (EEEEEE)'));
  const groupedByDeliveredAt = groupBy(deliveredOrders, 'deliveredAt');
  /**
   * 이슈와 주문이 같은 날짜인 경우 묶어주고 그렇지 않은 경우에는 별도로 표현해야한다.
   */
  const deliveryDates = uniq([...Object.keys(groupedByDeliveredAt), ...Object.keys(groupedByDate)]);
  /**
   * 현재 함수의 목적은 주문과 이슈를 묶어서 표현하는 것이다.
   * 주문에 묶을 수 없는, 즉 주문이 없고 이슈만 있는 경우가 존재하기 때문에 이를 위한 처리가 필요하다.
   * 한 화면에 같이 보여주기 때문에 서로 다른 형태의 데이터에 동일한 정렬을 적용해야한다.
   * 이 때문에 기존 정렬 기준이 되는 orderDate에 이슈 date를 임의로 넣어준다.
   */
  const orderWithIssues: OrderWithIssues[] = deliveryDates.map((date) => {
    // 주문이 배열 형태이나, 이는 배열을 그룹핑한 결과이기 때문에 현재는(정책상 주문을 하루에 하나만 생성하므로) length가 1인 경우만 존재한다.
    const order = groupedByDeliveredAt[date];
    if (order) {
      return {
        ...order[0],
        _ui: {
          storeIssues: groupedByDate[date],
        },
      };
    }
    // 주문이 없는 경우
    return {
      orderDate: groupedByDate[date][0].date,
      _ui: {
        storeIssues: groupedByDate[date],
      },
    };
  });

  // 주문일자를 기준으로 정렬한다.(최근 날짜가 위로 오도록 정렬)
  const groupedList = groupBy(
    [...noneDeliveryOrders, ...orderWithIssues].sort(
      (a, b) => new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime()
    ),
    groupingRule
  );
  return groupedList as Dictionary<(OrderDoc | OrderWithIssues)[]>;
};

export const historyExcelDownload = (date: string, items: (OrderDoc | OrderWithIssues)[]) => {
  const fileName = `주문내역_${date}`;
  const heading = [['일자', '내용', '수량', '과/면세', '공급가', '세액', '총금액']];

  const rows = items.map((item) => {
    const deliveredOrder = item.orderStatus === OrderStatusCode.DELIVERED ? (item as OrderDoc) : undefined;
    // 주문 상품
    const orderProducts = deliveredOrder ? deliveredOrder.products : undefined;
    // 배송비
    const deliveryFee = deliveredOrder ? deliveredOrder.deliveryFee : undefined;
    // 이슈
    const storeIssues = (item as OrderDoc & OrderUI)._ui?.storeIssues;
    const deliveredAt = item.deliveredAt ? reverseDeliveryDate(date, item.deliveredAt) : undefined;

    const productRows =
      orderProducts?.map((product) => {
        const { price, tax } = calculatePreTaxPrice(product.snapshotPrice ?? product.price, product.taxFree);

        return {
          date: deliveredAt ? formatDate(deliveredAt, 'yyyy-MM-dd') : '',
          text: product.fullName,
          volume: product.volume,
          taxFree: product.taxFree ? '면세' : '과세',
          price,
          tax: tax * product.volume,
          total: (price + tax) * product.volume,
        };
      }) ?? [];

    const deliveryFeeRow = deliveryFee
      ? [
          {
            date: deliveredAt ? formatDate(deliveredAt, 'yyyy-MM-dd') : '',
            text: '배송비',
            volume: 1,
            taxFree: '면세',
            price: deliveryFee,
            tax: 0,
            total: deliveryFee,
          },
        ]
      : [];

    const storeIssueRows =
      storeIssues?.map((issue) => {
        const price = issue.supplyPrice ?? 0;
        const tax = issue.tax ?? 0;
        const volume = issue.volume ?? 1;
        const total = price ? (price + tax) * volume : null;
        return {
          date: issue.date ? formatDate(issue.date, 'yyyy-MM-dd') : '',
          text: issue.message,
          volume,
          taxFree: issue.tax ? '면세' : '과세',
          price,
          tax,
          total,
        };
      }) ?? [];

    return [...productRows, ...deliveryFeeRow, ...storeIssueRows];
  });

  jsonToExcelFile(rows.flat(), fileName, heading);
};
