import { FC } from 'react';

import { formatNumber } from 'src/lib/1/util';
import { StoreIssueDoc } from 'src/lib/2/schema-store-issue';

import classes from './StoreIssueItem.module.scss';

type StoreIssueItemProps = {
  storeIssue: StoreIssueDoc;
};

const StoreIssueItem: FC<StoreIssueItemProps> = ({ storeIssue }) => {
  const price = storeIssue.supplyPrice ? storeIssue.supplyPrice + (storeIssue.tax ?? 0) : null;
  const volume = storeIssue.volume ?? 1;
  const total = price ? price * volume : null;
  const priceInfo = price ? `${formatNumber(price)} x ${formatNumber(volume)}` : null;
  return (
    <div className={classes.storeIssueItem}>
      <p className={classes.message}>{storeIssue.message}</p>
      <div className={classes.issuePrice}>
        <span>{priceInfo}</span>
        {total && <span>{formatNumber(total)}원</span>}
      </div>
    </div>
  );
};

export default StoreIssueItem;
