import { useMemo } from 'react';
import useSWR from 'swr';

import { StoreIssueDoc } from 'src/lib/2/schema-store-issue';
import { getStoreIssue } from 'src/lib/3/firebase-short-cut';

const getIssues = async (ids: string[]) => {
  const promises = ids.map((id) => getStoreIssue(id));
  return Promise.all(promises);
};

const useStoreIssuesByIds = (ids: string[] | null) => {
  const {
    data,
    isValidating: storeIssuesLoading,
    error: storeIssuesError,
  } = useSWR(ids ? ids : null, getIssues, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });
  const storeIssues = useMemo(
    () => (data ? (data.filter((issue) => issue !== undefined) as StoreIssueDoc[]) : undefined),
    [data]
  );

  return {
    storeIssues,
    storeIssuesLoading,
    storeIssuesError,
  };
};

export default useStoreIssuesByIds;
